"use client"
import styles from "./Modal.module.scss"
// import Script from "next/script"

export default function Modal({
  link = "795163201?h=c3f7dcdff1",
  video,
  close,
  image,
}) {
  const iframeStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  }
  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modal}>
        <section
          className={`${styles.modal__body} ${
            image ? styles.modal__bodyImage : ""
          }`}
        >
          <slot name="body">
            <button
              type="button"
              className={styles.btnClose}
              onClick={close}
              aria-label="Close"
            ></button>
            {!image ? (
              <>
                <div
                  style={{ padding: "56.25% 0 0 0", position: "relative" }}
                  dangerouslySetInnerHTML={{ __html: video }}
                >
                  {/* <iframe
                    src={`https://player.vimeo.com/video/${link}&title=0&byline=0&portrait=0`}
                    style={iframeStyle}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  ></iframe> */}
                </div>
              </>
            ) : (
              image && (
                <img
                  className={styles.intro__img}
                  src={image}
                  alt="modal image"
                />
              )
            )}
          </slot>
        </section>
      </div>
      <div className={styles.modalClose} onClick={close}></div>
    </div>
  )
}
