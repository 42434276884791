const DEFAULT_TIMEZONE = "CET"
const DEFAULT_TIMELANG = "en-GB"

export const getDate = (el) => {
  const date = new Date(el)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${String(day).padStart(2, "0")}.${String(month).padStart(
    2,
    "0"
  )}.${year}`
}

export const getDateWithTime = (
  el,
  lang = DEFAULT_TIMELANG,
  time_zone = DEFAULT_TIMEZONE
) => {
  if (lang === "en") lang = DEFAULT_TIMELANG

  const date = new Date(el)
  const optionsDate = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: time_zone,
  }
  const optionsTime = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: time_zone,
    hour12: false,
  }

  const formattedDate = date
    .toLocaleDateString(lang, optionsDate)
    .replace(/\//g, ".")

  const formattedTime = date.toLocaleTimeString(lang, optionsTime).slice(0, 5)
  return `${formattedDate} ${formattedTime}`
}

export const getDateWithMonth = (
  el,
  lang = DEFAULT_TIMELANG,
  time_zone = DEFAULT_TIMEZONE
) => {
  if (lang === "en") lang = DEFAULT_TIMELANG
  const date = new Date(el)
  const optionsDate = {
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: time_zone,
  }
  const formattedDate = date.toLocaleDateString(lang, optionsDate)
  return `${formattedDate}`
}

export const getDateWithMonthWithTime = (
  el,
  lang = DEFAULT_TIMELANG,
  time_zone = DEFAULT_TIMEZONE
) => {
  if (lang === "en") lang = DEFAULT_TIMELANG
  const date = new Date(el)
  const optionsTime = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: time_zone,
    hour12: false,
  }

  const formattedDate = getDateWithMonth(el, lang, time_zone)

  const formattedTime = date.toLocaleTimeString(lang, optionsTime).slice(0, 5)

  return `${formattedDate} ${formattedTime}`
}

export const getDateWithShortMonth = (
  el,
  lang = DEFAULT_TIMELANG,
  time_zone = DEFAULT_TIMEZONE
) => {
  if (lang === "en") lang = DEFAULT_TIMELANG
  const date = new Date(el)
  const options = { day: "numeric", month: "short", timeZone: time_zone }
  const formattedDate = date.toLocaleDateString(lang, options)

  const [day, month] = formattedDate.split(" ")

  return `${day} <span>${month}</span>`
}

export const getGMT = (el) => {
  // Create a new Date object for the current time
  const date = new Date()

  // Use Intl.DateTimeFormat to get the time zone offset
  const options = { timeZone: el, timeZoneName: "short" }
  const formatter = new Intl.DateTimeFormat("en-GB", options)
  const parts = formatter.formatToParts(date)

  // Find the GMT offset part
  const gmtOffset = parts.find((part) => part.type === "timeZoneName").value

  return gmtOffset
}

export const sortDates = (items) => {
  return items.sort((a, b) => new Date(b.date_time) - new Date(a.date_time))
}

export const isPastEvent = (el, time_zone = DEFAULT_TIMEZONE) => {
  // Текущее время с учетом временной зоны
  const now = new Date().toUTCString(DEFAULT_TIMELANG, {
    timeZone: time_zone,
  })
  const currentDateTime = new Date(now)

  // Время события с учетом временной зоны
  const eventDateTime = new Date(
    new Date(el).toUTCString(DEFAULT_TIMELANG, { timeZone: time_zone })
  )

  return eventDateTime < currentDateTime
}
