"use client"

import { useState, useEffect } from "react"
import Container from "@/components/Container/Container"
import Row from "@/components/Row/Row"
import Col from "@/components/Col/Col"
import styles from "./Speakers.module.scss"
import Speaker from "./Speaker"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import { useTranslations } from "next-intl"

const gridDefault = {
  cols: 12,
  sm: 24,
  md: 12,
  lg: 6,
}

const sliderBreakpoints = {
  960: {
    slidesPerView: 3,
    spaceBetween: 37,
  },
  1280: {
    slidesPerView: 4,
    spaceBetween: 74,
  },
}

export default function Speakers({
  speakers,
  title,
  grid = gridDefault,
  isShort = false,
}) {
  const t = useTranslations()

  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : null
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const isSwiperActive = windowWidth >= 960

  return (
    <div
      id="speakers"
      className={`${styles.speakers} ${isShort ? styles.speakers_short : ""}`}
    >
      <Container>
        <div className={styles.speakers__wrap}>
          <Row>
            <Col cols="12">
              <h2 className={styles.speakers__title}>
                {title || t("horizonPanel")}
              </h2>
            </Col>
          </Row>

          {speakers.length <= 4 ? (
            <Row className={styles.speakers__list}>
              {speakers.map((item, index) => (
                <Col
                  cols={grid.cols}
                  sm={grid.sm}
                  md={grid.md}
                  lg={grid.lg}
                  xl={grid.xl}
                  key={item.name + item.position}
                >
                  <Speaker isShort={isShort} item={item} />
                </Col>
              ))}
            </Row>
          ) : isSwiperActive ? (
            <Swiper
              className={styles.speakers__slider}
              slidesPerView={4}
              navigation={true}
              modules={[Navigation]}
              breakpoints={sliderBreakpoints}
            >
              {speakers.map((item, index) => (
                <SwiperSlide key={index}>
                  <Speaker isShort={isShort} item={item} grid={grid} />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Row className={styles.speakers__list}>
              {speakers.map((item, index) => (
                <Col
                  cols={grid.cols}
                  sm={grid.sm}
                  md={grid.md}
                  lg={grid.lg}
                  xl={grid.xl}
                  key={index}
                >
                  <Speaker isShort={isShort} item={item} />
                </Col>
              ))}
            </Row>
          )}
        </div>
      </Container>
    </div>
  )
}
